// LinkWithLanguage.jsx
import React, { useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import getToObject from '../resource/getToObject.js';

export const LinkWithLanguage = forwardRef(
  ({ to: propsTo = '', ...restProps }, ref) => {
    const { i18n } = useTranslation();
    const language = i18n.language;
    const to = useMemo(
      () => getToObject({ to: propsTo, language, shouldFilterSearch: true }),
      [language, propsTo]
    );
    return <Link ref={ref} to={to} {...restProps} />;
  }
);

LinkWithLanguage.displayName = 'LinkWithLanguage';

LinkWithLanguage.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default LinkWithLanguage;
